<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">业务干预</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">退款管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div style="background: rgb(238, 216, 219); margin-bottom: 10px">
          <div class="redDotBox">
            <span>仅支持题库订单和平台自有课程订单退款</span>
          </div>
        </div>
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="订单号" class="searchboxItem ci-full">
              <span class="itemLabel">订单号:</span>
              <el-input
                v-model="orderSn"
                type="text"
                size="small"
                clearable
                placeholder="请输入订单号"
              />
            </div>
            <div title="买家手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">买家手机号:</span>
              <el-input
                v-model="userPhone"
                type="text"
                size="small"
                clearable
                placeholder="请输入买家手机号"
              />
            </div>

            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="refundRecord()"
                >退款记录</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="订单编号"
                align="center"
                prop="orderSn"
                show-overflow-tooltip
                min-width="150"
              />

              <el-table-column
                label="商品类型"
                align="center"
                prop="productType"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="买家手机号"
                align="center"
                prop="mobile"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="订单金额"
                align="center"
                prop="orderMoney"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="实付金额"
                align="center"
                prop="paymentMoney"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="支付方式"
                align="center"
                prop="paymentMethod"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("ORDER_METHOD", scope.row.paymentMethod) }}
                </template>
              </el-table-column>

              <el-table-column
                label="订单状态"
                align="center"
                prop="orderState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ getOrderState(scope.row.orderStatus) }}
                </template>
              </el-table-column>
              <el-table-column
                label="支付时间"
                align="center"
                width="120"
                prop="payTime"
              >
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.payTime | momentDate }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="260px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    :disabled="!scope.row.operationRefund"
                    @click="InitiateRefund(scope.row)"
                    >发起退款</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="退款申请"
      top="20px"
      :visible.sync="dialogShow"
      width="50%"
      :before-close="handleClose"
      :modal="false"
      :center="true"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="退款原因：" prop="refundReasonCode">
          <!-- <el-input v-model="form.trainType"></el-input> -->
          <el-select
            v-model="form.refundReasonCode"
            clearable
            placeholder="请选择退款原因"
          >
            <el-option
              v-for="item in refundList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退款金额：">
          <span style="color: red">￥{{ form.paymentMoney }}</span>
        </el-form-item>
        <el-form-item label="退款备注：" prop="refundRemarks">
          <el-input
            type="textarea"
            v-model="form.refundRemarks"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <div class="flexcc">
          <el-button class="bgc-bv" @click="handleClose()">取 消</el-button>
          <el-button class="bgc-bv" @click="doSave()">保 存</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
import { Message } from "element-ui";

export default {
  name: "businessIntervention/refundManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  //页面初始化时不自动查询列表数据
  doNotInit: true,
  data() {
    return {
      orderSn: "",
      userPhone: "",
      dialogShow: false, //弹框显示
      refundList: [], //退款原因
      form: {
        refundReasonCode: "1", //退款原因
        refundRemarks: "", //退款备注
        orderSn: "", //订单编号
        paymentMethod: "", //支付方式
        paymentMoney: "", //实付金额
      },
      rules: {
        refundReasonCode: [
          { required: true, message: "请选择退款原因", trigger: "change" },
        ],
        refundRemarks: [
          { required: true, message: "请输入退款备注", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getCode();
    this.getTableHeight();
  },
  methods: {
    //订单状态转换
    getOrderState(orderState) {
      switch (orderState) {
        case "10":
          return "未支付";
        case "20":
          return "已完成";
        case "30":
          return "退款进行中";
        case "40":
          return "退款失败";
        case "50":
          return "有退款";
        case "60":
          return "已退款";
        default: {
          return "--";
        }
      }
    },
    getData(pageNum = 1) {
      if (this.orderSn == "" && this.userPhone == "") {
        Message.error({
          message: "订单号和买家手机号必须填一项",
          duration: "2000",
        });
        return;
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.orderSn) {
        params.orderSn = this.orderSn;
      }
      if (this.userPhone) {
        params.userPhone = this.userPhone;
      }
      this.doFetch(
        {
          url: "/order/main/order-main/toRefund/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //新增
    refundRecord() {
      this.$router.push({
        path: "/web/businessIntervention/refundRecord",
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //获取码值
    getCode() {
      let list = this.$setDictionary("PAY_REFUND_REASON", "list");
      for (const key in list) {
        this.refundList.push({
          value: key,
          label: list[key],
        });
      }
    },
    // 发起退款
    InitiateRefund(row) {
      const { orderSn, paymentMethod, paymentMoney } = row;
      this.$post(
        "/order/main/order-main/toRefund/tips",
        { orderSn },
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          if (res.message) {
            this.$confirm(res.message, "退款提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              confirmButtonClass: "confirmButton-feifan",
              type: "warning",
            }).then((res) => {
              this.dialogShow = true;
              this.form.orderSn = orderSn;
              this.form.paymentMethod = paymentMethod;
              this.form.paymentMoney = paymentMoney;
            });
          } else {
            this.dialogShow = true;
            this.form.orderSn = orderSn;
            this.form.paymentMethod = paymentMethod;
            this.form.paymentMoney = paymentMoney;
          }
        }
      });
    },
    //退款确认
    doSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            orderSn: this.form.orderSn,
            paymentMethod: this.form.paymentMethod,
            refundReasonCode: this.form.refundReasonCode,
            refundRemarks: this.form.refundRemarks,
          };
          this.$post("/pay/v1/pc/refund", params, 3000, true, 4).then((res) => {
            if (res.status == "0") {
              this.$message.success("操作成功");
              this.dialogShow = false;
              this.form = {
                refundReasonCode: "1", //退款原因
                refundRemarks: "", //退款备注
                orderSn: "", //订单编号
                paymentMethod: "", //支付方式
                paymentMoney: "", //实付金额
              };

              this.getData(-1);
            }
          });
        }
      });
    },
    //退款取消
    handleClose() {
      this.form = {
        refundReasonCode: "1", //退款原因
        refundRemarks: "", //退款备注
        orderSn: "",
        paymentMethod: "",
        paymentMoney: "",
      };
      this.dialogShow = false;
      this.$refs.form.clearValidate();
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == "true") {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style>
.confirmButton-feifan {
  background-color: #5c6be8 !important;
  color: white;
  border: none;
}
</style>
<style lang="less" scoped>
.redDotBox {
  margin-left: 13px;
  padding: 19px 0;
  color: #d90119;
  cursor: pointer;
  /deep/ .el-badge__content.is-dot {
    height: 8px !important;
  }
}
</style>
